'use strict';

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import Events from './events/Events.js'

let textureLoaded = false
let jsonLoaded = false

export default class Model extends Backbone.Model {

	initialize(options) {
		this.dispatch = options.dispatch
		this.dispatch.on( Events.SET_PAGE, this.setPage, this )
		this.dispatch.on( Events.MAP_READY, this.changeDealerId, this )
		this.dispatch.on( Events.CHANGE_DEALER, this.changeDealerName, this )
		this.dispatch.on( Events.CHANGE_FILTER, this.changeFilter, this )
	}

	setPage(data) {
		this.set({ route : data })
		this.dispatch.trigger( Events.MODEL_CHANGED )
	}

	preloadJSON( dataURL) {
		$.ajax({ url: dataURL, success: this.jsonLoaded, context: this })
	}

	jsonLoaded( data ) {
		jsonLoaded = true
		this.attributes.data = data
		this.dispatch.trigger( Events.JSON_LOADED )
	}

	setCookie(name, value, days) {
		document.cookie = name+'='+value+'; max-age='+60*60*24*days+'; path=/';
	}

	getCookie( cookie_name ) {
	  var cookie_string = document.cookie ;
	  if (cookie_string.length != 0) {
	  	var re = new RegExp('(^|;)[\\s]*'+cookie_name+'=([^;]*)');
	    var cookie_value = cookie_string.match( re );
	    if (cookie_value != undefined) {
	    	return decodeURIComponent( cookie_value[2] );
	    } else {
	    	return '';
	    }
	  }
	  return '' ;
	}

	deleteCookie( cookie_name ) {
	  document.cookie = cookie_name+'=; max-age=0; path=/' ;
	}

	changeDealerName(name) {
		if (name) {
			this.attributes.dealerName = name
			this.changeDealerId()
		}
	}

	changeDealerId() {
		if (this.attributes.data.dealers) {
			let dealer = _.where(this.attributes.data.dealers.list, { seo: this.attributes.dealerName })
			let index = _.indexOf(this.attributes.data.dealers.list, dealer[0])
			this.attributes.dealerId = index
			this.dispatch.trigger( Events.MODEL_CHANGED )
			this.dispatch.trigger( Events.SHOW_MODAL, this.attributes.dealerId )
		}
	}

	changeFilter(name) {
		if (name) {
			this.attributes.filter = name
			this.dispatch.trigger( Events.MODEL_CHANGED )
		}
	}

	defaults() {
		return { 	
			route: '',
			dealerName: undefined,
			dealerId: undefined,
			filter: null,
			data: {}
		}
	}
}