module.exports = (function(){
    return {
        MODEL_CHANGED: 'model_changed',
        SET_PAGE: 'set_page',
        JSON_LOADED: 'json_loaded',
        TRIGGER_ROUTE: 'trigger_route',
        SHOW_MODAL: 'show_modal',
        CHANGE_DEALER: 'change_dealer',
        CHANGE_FILTER: 'change_filter',
        MAP_READY: 'map_ready'
    };
})();