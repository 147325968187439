'use strict'

import _ from 'underscore'
import React from 'react'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Map from './Map.jsx'
import List from './List.jsx'

export default class MainView extends BaseComponent {

	constructor(props) {
		super(props)
		this.dispatch = this.props.dispatch
		this.model = this.props.model
		this.state = { screen : { width: window.innerWidth, height: window.innerHeight }, menu: false}
		this._bind('handleResize', 'toggleMenu', 'hideMenu')
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize)
	}

	toggleMenu() {
		if ( this.state.menu ) {
			this.setState({menu: false})
		} else {
			this.setState({menu: true})
		}
	}

	hideMenu() {
		this.setState({menu: false})
	}

	handleResize() {

	}

	getYear() {
		return new Date().getFullYear();
	}

	render() {
		let self = this
		let hotspots, controlsClass, page
		let route = this.props.route

		switch(route) {
			case 'home':
				page = <Map key="map"
								dispatch={ this.dispatch } 
								filters={ this.props.data.dealers.filters }
								markerData={ this.props.data.dealers.list } 
								dealerId={ this.props.dealerId } 
								screen={ self.state.screen } />
				break;
			case 'dealer':
				page = <Map key="map"
								dispatch={ this.dispatch } 
								filters={ this.props.data.dealers.filters }
								markerData={ this.props.data.dealers.list } 
								dealerId={ this.props.dealerId } 
								screen={ self.state.screen } />
				break;
			case 'filtr':
				page = <Map key="map"
								dispatch={ this.dispatch } 
								filters={ this.props.data.dealers.filters } 
								filter={ this.props.filter } 
								markerData={ this.props.data.dealers.list } 
								dealerId={ this.props.dealerId } 
								screen={ self.state.screen } />
				break;
			case 'list':
				page = <List key="list" 
								dispatch={ this.dispatch } 
								markerData={ this.props.data.dealers.list }
								screen={ self.state.screen } />
				break;
		}

		return (
			<div className="main">
				<header className="header">
					<h1>Wybierz Salon Audi</h1>
					<a href="/" className="logo">
						<svg x="0px" y="0px" viewBox="0 0 175.6 62">
							<path d="M144.2,0c-7,0-13.5,2.3-18.8,6.2C120.1,2.3,113.6,0,106.6,0c-7,0-13.5,2.3-18.8,6.2C82.5,2.3,76.1,0,69,0  c-7,0-13.5,2.3-18.8,6.2C45,2.3,38.5,0,31.4,0C14.1,0,0,13.9,0,31s14.1,31,31.4,31c7,0,13.5-2.3,18.8-6.2C55.5,59.7,62,62,69,62  c7,0,13.5-2.3,18.8-6.2C93,59.7,99.5,62,106.6,62c7,0,13.5-2.3,18.8-6.2c5.2,3.9,11.7,6.2,18.8,6.2c17.3,0,31.4-13.9,31.4-31  S161.5,0,144.2,0z M125.4,47c-3.8-4.3-6.1-9.9-6.1-16c0-6.1,2.3-11.7,6.1-16c3.8,4.3,6.1,9.9,6.1,16C131.5,37.1,129.2,42.7,125.4,47  z M87.8,47c-3.8-4.3-6.1-9.9-6.1-16c0-6.1,2.3-11.7,6.1-16c3.8,4.3,6.1,9.9,6.1,16C93.9,37.1,91.6,42.7,87.8,47z M50.2,47  c-3.8-4.3-6.1-9.9-6.1-16c0-6.1,2.3-11.7,6.1-16c3.8,4.3,6.1,9.9,6.1,16C56.4,37.1,54,42.7,50.2,47z M6.5,31  c0-13.5,11.2-24.5,24.9-24.5c5.1,0,9.9,1.5,13.9,4.2c-4.8,5.4-7.7,12.5-7.7,20.3c0,7.8,2.9,14.9,7.7,20.3c-4,2.6-8.7,4.2-13.9,4.2  C17.7,55.5,6.5,44.5,6.5,31z M55.1,51.3c4.8-5.4,7.7-12.5,7.7-20.3c0-7.8-2.9-14.9-7.7-20.3c4-2.6,8.7-4.2,13.9-4.2  s9.9,1.5,13.9,4.2c-4.8,5.4-7.8,12.5-7.8,20.3c0,7.8,2.9,14.9,7.8,20.3c-4,2.6-8.7,4.2-13.9,4.2C63.9,55.5,59.1,53.9,55.1,51.3z   M92.7,51.3c4.8-5.4,7.8-12.5,7.8-20.3c0-7.8-2.9-14.9-7.8-20.3c4-2.6,8.7-4.2,13.9-4.2s9.9,1.5,13.9,4.2  c-4.8,5.4-7.8,12.5-7.8,20.3c0,7.8,2.9,14.9,7.8,20.3c-4,2.6-8.7,4.2-13.9,4.2S96.7,53.9,92.7,51.3z M144.2,55.5  c-5.1,0-9.9-1.5-13.9-4.2c4.8-5.4,7.8-12.5,7.8-20.3c0-7.8-2.9-14.9-7.8-20.3c4-2.6,8.7-4.2,13.9-4.2c13.7,0,24.9,11,24.9,24.5  C169.1,44.5,157.9,55.5,144.2,55.5z"></path>
						</svg>
					</a>
				</header>
				{ page }
				<footer className="footer">
					<p className="copy">&copy; {this.getYear()} Audi Polska.</p>
					<div className="help-btn">Pomoc</div>
					<div className="audi-links">
						<a href="http://audi.pl/" data-bypass>audi.pl</a>
					</div>
				</footer>
			</div>
		)
	}
}
