'use strict'

import _ from 'underscore'
import Backbone from 'backbone'
import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom';
import Events from './events/Events.js'
import Router from './router.js'
import Model from './model.js'
import MainView from './components/MainView.jsx'

export default class Main {

	constructor() {
		this.dispatch = _.extend({}, Backbone.Events )
		this.model = new Model({ dispatch : this.dispatch })
		this.router = new Router({ model : this.model, dispatch: this.dispatch })
		this.init()
	}

	init() {
		this.model.preloadJSON('https://fbods.hypermedia.pl/xhr/api/xhr')
		// this.model.preloadJSON('/data/xhr_api.json')
		this.dispatch.on( Events.JSON_LOADED, this.ready, this )
		Backbone.history.start({pushState: true})
	}

	ready(data) {
		this.dispatch.on( Events.MODEL_CHANGED, this.render, this )
		this.render()
	}

	render() {
		ReactDOM.render(
			<MainView 
				data={ this.model.attributes.data } 
				dealerId={ this.model.get('dealerId') } 
				route={ this.model.get('route') } 
				filter={ this.model.get('filter') } 
				dispatch={ this.dispatch } />,	
			document.getElementById('app'))
	}
}

const app = new Main()

$(document).on("click", "a[href]:not([data-bypass])", function(evt) {
	// Get the absolute anchor href.
	var href = $(this).attr("href")

	evt.preventDefault()
	Backbone.history.navigate(href, true)
})