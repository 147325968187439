'use strict'

import _ from 'underscore'
import React from 'react'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'

export default class LocationModal extends BaseComponent {

	constructor(props) {
		super(props)
		this.dispatch = this.props.dispatch
	}


	render() {
		let contact = this.props.marker.contact.map((item, key) => {
			return <p key={ 'contact'+key }><span className="info-title">{ item.id } </span>{ item.value }</p>
		})
		let hours = this.props.marker.hours.map((item, key) => {
			return <p key={ 'contact'+key }><span className="info-title ">{ item.id } </span>{ item.value }</p>
		})
		let alt_contact = this.props.marker.alt_contact.map((item, key) => {
			if(item.value ==="m.me/2423694211038725") {
				let link = 'https://' + item.value;
				return <span className ="iconPosition" key={ 'contact'+key }>
						<img className="contactImg" src="../assets/img/messenger.png"/>
						<p className="fontValueContact">
						<a className="fontValueContact" onClick={()=> window.open(link, "_blank")}>m.me/Audi-PorscheInterAuto</a></p>
					</span> 
			}else 
			if(item.field==='messenger') {
				let link = 'https://' + item.value;
				return <span className ="iconPosition" key={ 'contact'+key }>
							<img className="contactImg" src="../assets/img/messenger.png"/>
							<p className="fontValueContact">
								<a className="fontValueContact" onClick={()=> window.open(link, "_blank")}>{ item.value }</a></p>
								
						</span> 
			} else if (item.field==='whatsapp') {
				return <span className ="iconPosition" key={ 'contact'+key }>
							<img className="contactImg" src="../assets/img/whatsup.png"/>
							<p className="fontValueContact">{item.name}: { item.value }</p>
						</span>
			}else if(item.field==='instagram') {
				return <span className ="iconPosition" key={ 'contact'+key }>
							<img className="contactImg" src="../assets/img/instagram.png"/>
							<p className="fontValueContact"><a className="fontValueContact"
							className="fontValueContact" onClick={()=> window.open(item.value, "_blank")}>{ item.value }</a></p>
						</span>
			} else if(item.field==='skype') {
				return <span className ="iconPosition" key={ 'contact'+key }>
							<img className="contactImg" src="../assets/img/skype.png"/> 
							<p className="fontValueContact"><a  target="_blank" className="fontValueContact"
							className="fontValueContact" onClick={()=> window.open(item.value, "_blank")}>{ item.value }</a></p>
						</span>
			}

		})

		let doortodoors = this.props.marker.doortodoors.map((item, key, isCheckbox) => {
			if(item.isCheckbox === '1' && item.value === '1'){
				return <p key={ 'contact'+key }><span className="info-title">{ item.name }:&nbsp; </span></p>
			} else if( item.isCheckbox === '1' && item.value === '' && item.name === 'Usługi serwisowe door-to-door') { 
				// return <p key={ 'contact'+key }><span className="info-title">{ item.name }:&nbsp; </span>usługa niedostępna</p>
			}
			let CheckMail = item.value;
				CheckMail = CheckMail.split("@");
			if(item.value !== '') {
				if(item.isCheckbox === '0'  && CheckMail.length > 1 ){
					return <p key={ 'contact'+key }><span className="lightFont" >email: &nbsp;</span><strong><a href={ "mailto:"+ item.value } data-bypass>{ item.value }</a></strong></p>
				} else { 
					return <p key={ 'contact'+key }><span className="lightFont" >tel: &nbsp;</span><strong>{ item.value }</strong></p>
				}
			}
		})


		return (

			<div className="location-info">
				<h2 className="name">{ this.props.marker.name }</h2>
				
				<p className="address">{ this.props.marker.street } / { this.props.marker.postalCode } { this.props.marker.city } <a className="directions" target="_blank" title="Wskazówki dojazdu" href={ "https://www.google.com/maps/dir//" + this.props.marker.coords }  data-bypass><img src="../assets/img/directions.png"/></a></p>
				{ contact }
				{ _.where(this.props.marker.param, { id: "www2" })[0] ? <p className="website mar"><span className="info-title"></span><a href={ _.where(this.props.marker.param, { id: "www2" })[0].value } className="website" target="_blank" data-bypass>Sprzedaż flotowa</a></p> : ''}
				{ this.props.marker.hours.length > 0 ? <p className="hours"><span className="info-title">Godziny otwarcia:</span></p> : '' }
				{ hours }
				<div className="marginSite">
				{ this.props.marker.email != ''? <p className="email"><span className="info-title">email: </span><a href={ "mailto:"+this.props.marker.email } data-bypass>{ this.props.marker.email }</a></p> : ''}
				{ this.props.marker.wwww != ''? <p className="website"><span className="info-title">www: </span><a href={ this.props.marker.www } className="website" target="_blank" data-bypass>{ this.props.marker.www }</a></p> : ''}
				</div>
				{ this.props.marker.doortodoors.length > 0  ? <p className="doortodoors" >{ doortodoors }</p> : '' }
				<div className="marginSite">
				{ this.props.marker.alt_contact.length > 0  ?	<span className="info-title">Skontaktuj się z nami również na: <p>{ alt_contact }</p></span> : '' }
				</div>
			</div>
		)
	}
}