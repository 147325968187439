'use strict'

import _ from 'underscore'
import $ from 'jquery'
import React from 'react'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'
import LocationModal from './LocationModal.jsx'
import TransitionGroup from 'react-addons-css-transition-group'

export default class Map extends BaseComponent {

  constructor(props) {
    super(props)
    this.dispatch = this.props.dispatch
    this.state = { selected: -1, filters: this.props.filters, mobileFilters: false, dl: "", infoWindow: null }
    this._bind('closeModal', 'toggleFilter', 'toggleFilterMenu', 'selectDealer', 'findClosest', 'route', 'showInfoWindow', 'zoomControl', 'showLocation')
    this.dispatch.on( Events.SHOW_MODAL, this.selectDealer )
  }

  zoomControl(controlDiv, map) {


    controlDiv.style.padding = '5px';
    controlDiv.style.margin = '5px';


    let controlWrapper = document.createElement('div');
    controlWrapper.style.backgroundColor = 'transparent';
    controlWrapper.style.cursor = 'pointer';
    controlWrapper.style.textAlign = 'center';
    controlWrapper.style.width = '36px';
    controlWrapper.style.height = '72px';
    controlDiv.appendChild(controlWrapper);


    let zoomInButton = document.createElement('div');
    zoomInButton.style.width = '28px';
    zoomInButton.style.height = '28px';
    zoomInButton.style.margin = '4px';

    zoomInButton.style.backgroundImage = 'url("/assets/img/plus.jpg")';
    controlWrapper.appendChild(zoomInButton);


    let zoomOutButton = document.createElement('div');
    zoomOutButton.style.width = '28px';
    zoomOutButton.style.height = '28px';
    zoomOutButton.style.margin = '4px';

    zoomOutButton.style.backgroundImage = 'url("/assets/img/minus.jpg")';
    controlWrapper.appendChild(zoomOutButton);


    google.maps.event.addDomListener(zoomInButton, 'click', function () {
      map.setZoom(map.getZoom() + 1);
    });


    google.maps.event.addDomListener(zoomOutButton, 'click', function () {
      map.setZoom(map.getZoom() - 1);
    });

  }


  showInfoWindow(marker, city, name) {

    let _this = this

    marker.addListener('mouseover', function () {
      let overlay = new google.maps.OverlayView()
      overlay.draw = function () { }
      overlay.setMap(this.map)

      let proj = overlay.getProjection()
      let pos = marker.getPosition()
      let p = proj.fromLatLngToContainerPixel(pos)
      let item = <div className="infowindow" style={{ left: p.x + 16 + 'px', top: p.y - 48 + 'px' }}><div className="puzzle"></div>{name}<br /><span>{city}</span></div>
      _this.setState({ infoWindow: item })
      if (marker.icon == "/assets/img/marker-dealer.png" || marker.icon.url == "/assets/img/marker-dealer.png") {
        marker.setIcon("/assets/img/marker-dealer-h.png");
      } else {
        marker.setIcon("/assets/img/marker-service-h.png");
      }

    });

    marker.addListener('mouseout', function () {
      _this.setState({ infoWindow: null })
      if (marker.icon == "/assets/img/marker-dealer-h.png" || marker.icon.url == "/assets/img/marker-dealer-h.png") {
        marker.setIcon("/assets/img/marker-dealer.png");
      } else {
        marker.setIcon("/assets/img/marker-service.png");
      }
    });
  }

  componentDidMount() {
    let _this = this
    let zoomLevel = 6
    if (this.props.screen.width > 1600) {
      zoomLevel = 7
    }
    this.markers = []
    this.filteredMarkers = []
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: zoomLevel,
      center: new google.maps.LatLng(52.4918461, 19.1627574),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      disableDefaultUI: true,
      styles: [
        {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#444444"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
            {
              "color": "#f2f2f2"
            },
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "gamma": "1"
            },
            {
              "lightness": "-14"
            },
            {
              "saturation": "-90"
            },
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "saturation": "-65"
            },
            {
              "lightness": "45"
            },
            {
              "gamma": "1.78"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
            {
              "saturation": -100
            },
            {
              "lightness": 45
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "saturation": "-33"
            },
            {
              "lightness": "22"
            },
            {
              "gamma": "2.08"
            }
          ]
        },
        {
          "featureType": "transit.station.airport",
          "elementType": "geometry",
          "stylers": [
            {
              "gamma": "2.08"
            },
            {
              "hue": "#ffa200"
            }
          ]
        },
        {
          "featureType": "transit.station.airport",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.station.rail",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.station.rail",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "simplified"
            },
            {
              "saturation": "-55"
            },
            {
              "lightness": "-2"
            },
            {
              "gamma": "1.88"
            },
            {
              "hue": "#ffab00"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
            {
              "color": "#bbd9e5"
            },
            {
              "visibility": "simplified"
            }
          ]
        }
      ]
    })

    let zoomControlDiv = document.createElement('div');
    let zoomControl = new this.zoomControl(zoomControlDiv, this.map);

    zoomControlDiv.index = 1;
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);

    for (let i = 0; i < this.props.markerData.length; i++) {
      const nr = i + 1
      const coords = this.props.markerData[i].coords.split(',')
      const latLng = new google.maps.LatLng(coords[0], coords[1])
      let markerURL = '/assets/img/marker-dealer.png'
      if (this.props.markerData[i].filters.indexOf('salon') === -1 && this.props.markerData[i].filters.indexOf('serwis') >= 0) {
        markerURL = '/assets/img/marker-service.png'
      }
      let marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: {
          url: markerURL,
          size: new google.maps.Size(62, 94),
          scaledSize: new google.maps.Size(31, 47),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 47)
        }
      })
      marker.addListener('click', () => {
        _this.dispatch.trigger(Events.TRIGGER_ROUTE, { route: "dealer/" + this.props.markerData[i].seo, trigger: true })
        this.showModal(i)
      })
      this.showInfoWindow(marker, this.props.markerData[i].city, this.props.markerData[i].name)
      this.markers.push(marker)
    }

    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].map != null) {
        _this.filteredMarkers.push(this.markers[i])
      }
    }

    let clusterStyles = [
      {
        textColor: 'white',
        url: '/assets/img/marker-cluster.png',
        height: 57,
        width: 31,
        textSize: 16,
        anchor: [10, 31]
      },
      {
        textColor: 'white',
        url: '/assets/img/marker-cluster.png',
        height: 57,
        width: 31,
        textSize: 16,
        anchor: [10, 31]
      },
      {
        textColor: 'white',
        url: '/assets/img/marker-cluster.png',
        height: 57,
        width: 31,
        textSize: 16,
        anchor: [10, 31]
      }
    ];

    this.mcOptions = {
      gridSize: 50,
      styles: clusterStyles,
      maxZoom: 15
    };

    // Add a marker clusterer to manage the markers.
    this.markerCluster = new MarkerClusterer(this.map, this.filteredMarkers, this.mcOptions)

    this.autocomplete = new google.maps.places.Autocomplete(
      (document.getElementById('autocomplete')), {
      types: ['(cities)'],
      componentRestrictions: { 'country': 'pl' }
    })
    //this.places = new google.maps.places.PlacesService(this.map)
    this.service = new google.maps.places.AutocompleteService();
    this.autocomplete.addListener('place_changed', this.onPlaceChanged.bind(this))
    this.map.addListener('bounds_changed', function () {
      _this.autocomplete.setBounds(_this.map.getBounds());
      $(".search-container").css("display", "none")
    });

    google.maps.event.addListenerOnce(this.map, 'idle', function () {
      _this.dispatch.trigger(Events.MAP_READY, '')
    })

    document.getElementById("autocomplete").onclick = (e) => {
      if (e.target.value.length > 2) {
        $(".search-container").css("display", "block")
      }
    }

    document.getElementById("autocomplete").oninput = (e) => {

      let dl = []
      let j = 0

      for (let i = 0; i < _this.props.markerData.length; i++) {
        if ((_this.props.markerData[i].name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)) {
          let temp = <div key={i} className="pac-item areasearch" onClick={this.route.bind(null, this.props.markerData[i].seo)}><span className="pac-icon pac-icon-areas"></span><span className="pac-item-query"><span className="pac-matched"></span>{_this.props.markerData[i].name}</span></div>
          dl.push(temp)
          j++
        }
      }
      if (e.target.value.length > 2 && j > 0) {
        this.setState({ dl: dl })

        $(".search-container").css('display', 'block')
        let top = parseInt($(".pac-container").css("top").replace("px", ''))
        top += 32 * j
        $(".pac-container").css("top", top + "px")


      } else {

        this.setState({ dl: '' })
      }
    }

    // get location

    document.getElementById("location").onclick = (e) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showLocation);
      }
    }

    // check all
    if (this.props.filter == 'centra-flotowe') {
      this.setState({ filters: this.state.filters.map((item, key) => { item.value = false; return item }) })
      this.toggleFilter(2)
    } else {
      this.setState({ filters: this.state.filters.map((item, key) => { item.hidden == "0" || item.id == 'flota' ? item.value = true : item.value = false; return item }) })
    }
    const observer = new MutationObserver((mutations) => {
      const params = new URLSearchParams(window.location.search);
      const name = params.get('filtr');
      if (name) {
        this.state.filters.forEach((filter, index) => {
          if (filter.id !== name) {
            this.toggleFilter(index);
          }
        });
      }
      observer.disconnect();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

  }



  showLocation(position) {
    const closestMarker = this.findClosest(position.coords.latitude, position.coords.longitude)
    if (closestMarker != -1) {
      //const coords = closestMarker.coords.split(',')
      const latLng = new google.maps.LatLng(closestMarker.position.lat(), closestMarker.position.lng())
      this.map.panTo(latLng)
      this.map.setZoom(11);
    }

  }

  route(route) {
    this.dispatch.trigger(Events.TRIGGER_ROUTE, { route: "dealer/" + route, trigger: true })
  }

  selectDealer(e) {
    //console.log(e)
    if (e != -1) {
      $("#autocomplete").val(this.props.markerData[e].name)
      $(".search-container").css("display", "none")

      const coords = this.props.markerData[e].coords.split(',')
      const latLng = new google.maps.LatLng(coords[0], coords[1])
      this.map.panTo(latLng)
      this.map.setZoom(11)

      this.showModal(e)
    }
  }

  onPlaceChanged(e) {
    let _this = this
    let autocompleteService, placesService
    let place = this.autocomplete.getPlace()

    // http://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
    if (typeof place.address_components == 'undefined') {
      autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          'input': place.name,
          'offset': place.name.length,
          'componentRestrictions': { 'country': 'pl' },
          'types': ['(cities)']
        },
        function listentoresult(list, status) {
          //console.log(list)
          if (list == null || list.length == 0) {
            //console.log("No results");
          } else {
            placesService = new google.maps.places.PlacesService(document.getElementById('autocomplete'));
            placesService.getDetails(
              { 'reference': list[0].reference },
              function detailsresult(result, placesServiceStatus) {
                console.log(result)
                _this.setMap(result)
              }
            );
          }
        }
      );
    } else {
      this.setMap(place)
    }
    //this.map.setCenter({lat: 15, lng: 0});
    //this.map.setZoom(2);
  }

  setMap(place) {
    //console.log(place)
    const closestMarker = this.findClosest(place.geometry.location.lat(), place.geometry.location.lng())
    if (closestMarker != -1) {
      //const coords = closestMarker.coords.split(',')
      const latLng = new google.maps.LatLng(closestMarker.position.lat(), closestMarker.position.lng())
      this.map.panTo(latLng)
      this.map.setZoom(11);
    }
  }


  findClosest(lat, lng) {
    const R = 6371; // radius of earth in km
    let distances = []
    let closest = -1

    for (let i = 0; i < this.filteredMarkers.length; i++) {
      //console.log(this.filteredMarkers[i])
      //if (this.markers[i].map != null ) {
      //const coords = this.filteredMarkers[i].coords.split(',')
      const mlat = this.filteredMarkers[i].position.lat()
      const mlng = this.filteredMarkers[i].position.lng()
      const dLat = this.rad(mlat - lat)
      const dLong = this.rad(mlng - lng)
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(lat)) * Math.cos(this.rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c
      distances[i] = d
      if (closest == -1 || d < distances[closest]) {
        closest = i
      }
      //}
    }
    return this.filteredMarkers[closest]
  }

  rad(x) {
    return x * Math.PI / 180
  }

  showModal(id) {
    this.setState({ selected: id })
  }

  closeModal() {
    this.setState({ selected: -1 })
    this.dispatch.trigger(Events.TRIGGER_ROUTE, { route: "/", trigger: true })
  }

  toggleFilter(index) {
    let _this = this

    this.markerCluster.clearMarkers()
    this.filteredMarkers = []

    let newFilters = JSON.parse(JSON.stringify(this.state.filters))

    let countChecked = 0;
    newFilters.forEach(item => {
      if ((item.hidden == "0" || item.id == 'flota') && item.value) {
        countChecked++;
      }
    })

    if (newFilters[index].value === true && countChecked > 1) {
      newFilters[index].value = false
    } else {
      newFilters[index].value = true
    }

    this.setState({ filters: newFilters })
    for (let i = 0; i < this.markers.length; i++) {
      this.setMarkerVisibility(this.markers[i], this.props.markerData[i], newFilters);
      if (this.markers[i].map != null) {
        _this.filteredMarkers.push(this.markers[i])
      }
    }
    this.markerCluster.addMarkers(this.filteredMarkers)

  }

  toggleFilterMenu() {
    let mf = true
    if (this.state.mobileFilters) {
      mf = false
    }
    this.setState({ mobileFilters: mf })
  }

  setMarkerVisibility(marker, data, filters) {
    const filterValues = filters.map((item, key) => {
      if (item.value) {
        return item.id
      }
    })
    if (this.intersection(data.filters, _.compact(filterValues)).length > 0) {
      if (marker.map === null) {
        marker.setMap(this.map)
      }
    } else {
      marker.setMap(null)
    }
  }

  // returns array of intersecting values from two arrays
  intersection(a, b) {
    let c = a.filter(function (n) {
      return b.indexOf(n) != -1;
    })
    return c
  }

  render() {
    let modal = ''
    let filters = []

    this.state.filters.map((item, key) => {
      let filter

      if (item.hidden == "0" || item.id == 'flota') {
        filter = (
          <li key={'filter' + key} >
            <div id={item.id} name={item.id} className={item.value === true ? 'filter active' : 'filter'} onClick={this.toggleFilter.bind(this, key)}>
              {item.name} <span id="italicSurname">{item.additional_name}</span>
            </div>
          </li>
        )
      }

      filters.push(filter)
    })

    if (this.state.selected >= 0) {
      modal = <LocationModal key={modal + this.state.selected}
        markerId={this.state.selected}
        marker={this.props.markerData[this.state.selected]}
        close={this.closeModal} />
    }

    return (
      <div className="page map">
        <div className="map-menu">
          <div className="map-menu-item">
            <div className={this.state.mobileFilters === true ? "mobile-filter-toggle active" : "mobile-filter-toggle"} onClick={this.toggleFilterMenu}>
              <span>filtrowanie</span>
              <svg x="0px" y="0px" viewBox="0 0 24 20" className="filters-icon">
                <rect y="2" style={{ fill: "#FFFFFF" }} width="24" height="2" />
                <rect y="9" style={{ fill: "#FFFFFF" }} width="24" height="2" />
                <rect y="16" style={{ fill: "#FFFFFF" }} width="24" height="2" />
                <g className="circle-1">
                  <circle style={{ fill: "#FFFFFF" }} cx="5" cy="3" r="3" />
                  <circle style={{ fill: "#19191A" }} cx="5" cy="3" r="1" />
                </g>
                <g className="circle-2">
                  <circle style={{ fill: "#FFFFFF" }} cx="18" cy="10" r="3" />
                  <circle style={{ fill: "#19191A" }} cx="18" cy="10" r="1" />
                </g>
                <g className="circle-3">
                  <circle style={{ fill: "#FFFFFF" }} cx="9" cy="17" r="3" />
                  <circle style={{ fill: "#19191A" }} cx="9" cy="17" r="1" />
                </g>
              </svg>
            </div>
          </div>
          <div className="map-menu-item">
            <a href="/lista-partnerow" className="list-link">lista partnerów</a>
          </div>
        </div>
        <div className={this.state.mobileFilters === true ? "filters-holder active" : "filters-holder"}>
          <ul className="filters">
            {filters}
          </ul>
        </div>
        <div className="map-container">
          <div id="map"></div>
          <TransitionGroup transitionName="marker" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
            {this.state.infoWindow}
          </TransitionGroup>
          <div className="search">
            <div className="search-btn"></div>
            <input type="text" id="autocomplete" placeholder="Wpisz lokalizację..." />
          </div>
          <a href="#" className="location-btn" id="location">Pobierz lokalizację</a>
          <div className="search-container"> {this.state.dl} </div>
          {modal}
        </div>
      </div>
    )
  }
}
