'use strict'

import _ from 'underscore'
import React from 'react'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'
import LocationInfo from './LocationInfo.jsx'

export default class LocationModal extends BaseComponent {

	constructor(props) {
		super(props)
		this.dispatch = this.props.dispatch
		this._bind('close')
	}

	close() {
		this.props.close()
	}

	render() {

		let dl = []

	     for (var i = 0; i < this.props.marker.filters.length; i++) {
	       if (this.props.marker.filters[i] == "salon") {
	          let temp = <li key={ i }>Sprzedaż/serwis</li>
	          dl.push(temp)
	       } else if (this.props.marker.filters[i] == "serwis") {
	          let temp = <li key={ i }>Serwis</li>
	          dl.push(temp)
	       } else if (this.props.marker.filters[i] == "flota") {
	          let temp = <li key={ i }>Centrum Flotowe</li>
	          dl.push(temp)
	       } else if (this.props.marker.filters[i] == "selectplus") {
	          let temp = <li key={ i }>Audi Select <i>:plus</i></li>
	          dl.push(temp)
	       } else if (this.props.marker.filters[i] == "etron") {
	          let temp = <li key={ i }>E-tron</li>
	          dl.push(temp)
	       }  else if (this.props.marker.filters[i] == "sport") {
	          let temp = <li key={ i }>Partner R/RS</li>
	          dl.push(temp)
	       } 
	     }

		return (
			<div className="location-modal">
				<div className="location-modal-wrap">
					<div className="close" onClick={ this.close }></div>
					<div className="image" style={{ backgroundImage: 'url('+this.props.marker.file+')' }}></div>
					<ul className="dealer-filters">{ dl }</ul>
					<LocationInfo marker={ this.props.marker } />
				</div>
			</div>
		)
	}
}
