'use strict'

import Backbone from 'backbone'
import Events from './events/Events.js'

export default class Router extends Backbone.Router {

	get routes() {
		return {
			'': 'home',
			'dealer': 'dealer',
			'dealer/:name': 'dealer',
			'filtr/:name': 'filtr',
			'filtr': 'filtr',
			'lista-partnerow': 'list',
		}
	}

	constructor(options) {
		super(options)
		this.model = options.model
		this.dispatch = options.dispatch
		this.dispatch.on( Events.TRIGGER_ROUTE, this.triggerRoute, this)
	}

	initialize() {
		this.bind('route', this.change)
	}

	dealer(name) {
		this.dispatch.trigger( Events.CHANGE_DEALER, name )
	}

	filtr(name) {
		this.dispatch.trigger( Events.CHANGE_FILTER, name )
	}

	change(route) {
		this.dispatch.trigger( Events.SET_PAGE, route )
		if( typeof ga != 'undefined' ) {
			ga('send', 'pageview', '/'+Backbone.history.getFragment())
		}
	}

	triggerRoute( data ) {
		this.navigate(data.route, {trigger: data.trigger});
	}
}
