'use strict'

import _ from 'underscore'
import React from 'react'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'
import LocationInfo from './LocationInfo.jsx'

export default class List extends BaseComponent {

	constructor(props) {
		super(props)
		this.dispatch = this.props.dispatch
	}

	// http://stackoverflow.com/questions/15887900/group-objects-by-property-in-javascript
	groupBy(arr, key) {
    let newArr = [],
        types = {},
        newItem, i, j, cur
    for (i = 0, j = arr.length; i < j; i++) {
        cur = arr[i];
        if (!(cur[key] in types)) {
            types[cur[key]] = { city: cur[key], data: [] }
            newArr.push(types[cur[key]])
        }
        types[cur[key]].data.push(cur)
    }
    return newArr
  }

	render() {
		let dealers = (
			this.props.markerData.map((item, key)=>{
				if ( item.filters.indexOf('salon') >= 0 ) {
					return <li key={ "dealer"+key }><LocationInfo marker={ item } /></li>
				}
			})
		)

		let service = (
			this.props.markerData.map((item, key)=>{
				if ( item.filters.indexOf('serwis') >= 0 && item.filters.indexOf('salon') < 0 ) {
					return <li key={ "service"+key }><LocationInfo marker={ item } /></li>
				}
			})
		)
		
		return (
			<div className="page list">
				<a href="/" className="btn-back">{"< Powrót"}</a>
				<h3>Partnerzy</h3>
				<ul className="dealers">
					{ dealers }
				</ul>
				<h3>Serwisy</h3>
				<ul className="service">
					{ service }
				</ul>
			</div>
		)
	}
}
